import type { TargetElement } from '#core/types'

let viewportObserver: IntersectionObserver

export const useObserverState = () => {
  const seenIds = useState('seenIds', () => new Set<string>())
  const visibleIds = useState('visibleIds', () => new Set<string>())

  const add = (id: string) => {
    seenIds.value.add(id)
    visibleIds.value.add(id)
  }

  return {
    seenIds,
    visibleIds,
    add,
  }
}

export const useSetupViewportObserver = (boardId?: string) => {
  const taskQueue = new IdleTaskQueue()
  const { seenIds, visibleIds } = useObserverState()

  const clear = () => {
    seenIds.value.clear()
    visibleIds.value.clear()
  }

  watch(
    () => boardId,
    () => {
      clear()
    }
  )

  onBeforeMount(() => {
    logger.log('Setting up viewport observer')
    viewportObserver = new IntersectionObserver((entries) => {
      requestAnimationFrame(() => {
        entries.forEach((entry) => {
          const element = entry.target
          const id = element.getAttribute('data-id')

          if (!id) {
            logger.error('Element entered viewport, but ID could not be found.')
            return
          }

          if (!entry.isIntersecting) {
            // visibleIds.value.delete(id)
            return
          }

          if (!seenIds.value.has(id) || !visibleIds.value.has(id)) {
            /**
             * Hmm, when I perform this action, it seems smoother than running it with a queue.
             */
            taskQueue.addTask(() => {
              seenIds.value.add(id)
              visibleIds.value.add(id)
            })
          }
        })
      })
    })
  })

  onBeforeUnmount(() => {
    viewportObserver?.disconnect()
  })

  return {
    clear,
  }
}

export const useViewportObserver = (id: string, ref: TargetElement) => {
  const { seenIds } = useObserverState()
  let element: HTMLElement

  const bypassViewportCheck = () => {
    seenIds.value.add(id)
  }

  onMounted(() => {
    if (seenIds.value.has(id)) {
      return
    }

    element = unrefElement(evaluativeFn(ref))
    if (!element) {
      logger.error('Tried to observe element, but the ref hasn‘t loaded.')
      return
    }

    viewportObserver?.observe(element)
  })

  onUnmounted(() => {
    if (element) {
      viewportObserver?.unobserve(element)
    }
  })

  return {
    hasBeenInViewport: computed(() => seenIds.value.has(id)),
    bypassViewportCheck,
  }
}
